<template>
  <div class="container">
    <div class="title">投资金额</div>
    <el-form>
      <el-form-item label="合作方投资金额">
        <el-input
          v-model.number="investForm.coop_amount"
          placeholder="开店投资金额"
          type="number"
        />元
      </el-form-item>
      <el-form-item label="海马运动投资金额">
        <el-input
          v-model.number="investForm.self_amount"
          placeholder="开店投资金额"
          type="number"
        />元
      </el-form-item>
       <el-form-item label="收益分成阶段">
          <div>
            {{divide_type[investForm.divide_type] || ''}}
          </div>
      </el-form-item>
       <el-form-item label="开店基金分成情况">
       {{fund_type[investForm.fund_type] || ''}}
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="updateInitAmount">保保存投资</el-button>
    <div class="title">收益分成</div>
    <div v-loading="loading">
         <el-button v-if="ratios.length == 0" type="primary" @click="initRatio">获取收益分成</el-button>
      <el-form>
        <el-form-item
          :label="ratioTypeMap[r.type]"
          v-for="(r, i) in ratios"
          :key="i"
        >
          <div class="flex" style="width: 200px">
            <el-input
              @blur="blurInput"
              v-model.number="r.ratio"
              placeholder="比例,如0.5"
              type="number"
              :max="1"
              :min="0"
            ></el-input>
            <el-button type="primary" @click="updateRatioInfo(r)"
              >更新</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amountShow: false,
      investForm: {
        coop_amount: "",
        self_amount: "",
      },
      ratioTypeMap: {
        1: "收益分成",
        2: "开店基金",
      },
      divide_type:{
        1:'门店加速阶段',
        2:'海马加速阶段',
        3:'常规阶段'
      },
       fund_type:{
           1:'开店基金分成中',
           2:'已达目标',
      },

      mechanism: {},
      form: {},
      DataList: [],
      ratios: [],
      Option: [
        { name: "机构名称", value: "name", type: "custom" },
        { name: "投资金额", value: "mechanism.init_amount" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      formShow: false,
      loading: false,
      current: {},
      mode: "add",
      row: {},
    };
  },

  mounted() {
    this.id = this.$route.query.mecID;
  },

  methods: {
    getList() {
      this.getMecRatios();
      this.getInvest();
    },
    updateInitAmount() {
      if (this.investForm.id) {
        let data = {
          id: this.investForm.id,
          mechanism_id: this.id,
          coop_amount: this.investForm.coop_amount,
          self_amount: this.investForm.self_amount,
        };
        this.$post("/user/mechanismInvest/update", data).then((res) => {
          this.$message("更新成功");
          this.getInvest();
        });
      } else {
        let data = {
            mechanism_id: this.id,
          coop_amount: this.investForm.coop_amount,
          self_amount: this.investForm.self_amount,
        };
        this.$post("/user/mechanismInvest/insert", data).then((res) => {
          this.$message("更新成功");
        this.getInvest();
        });
      }
    },
    //投资金额
    getInvest() {
      this.$get("/user/mechanismInvest/queryByMec", {
        mechanism_id: this.id,
      }).then((res) => {
        this.investForm = res.data.data || {};
      });
    },
    blurInput(v) {
      let value = v.target.value;
      if (value >= 1) {
        this.$message("分成比例不能大于等于1");
      }
      if (value < 0) {
        this.$message("分成比例不能小于0");
      }
    },
    initRatio(){
         let data = {
        mechanism_id: this.id,
      };
      this.$get("/user/unionMechanismRatio/initRatio", data).then((res) => {
            this.getMecRatios()
      });
    },
    getMecRatios() {
      let data = {
        mechanism_id: this.id,
      };
      this.$get("/user/unionMechanismRatio/query", data).then((res) => {

       this.ratios = res.data.data;

        this.loading = false;
      });
    },
    updateRatioInfo(row) {
      let value = row.ratio;
      if (value >= 1) {
        this.$message("分成比例不能大于等于1");
        return;
      }
      if (value < 0) {
        this.$message("分成比例不能小于0");
        return;
      }
      let data = {
        id: row.id,
        ratio: value,
      };
      this.$post("/user/unionMechanismRatio/update", data).then((res) => {
        this.$message("更新成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title{
    padding: 20px 0 10px;
    font-size: 16px;
    font-weight: 600;
}
</style>