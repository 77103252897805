<template>
  <div>
    <el-steps :active="curStage" finish-status="success" simple>
      <el-step v-for="(item, i) in stageStep" :key="i" :title="item.name" />
    </el-steps>

    <el-form style="margin-top: 20px" :inline="true" class="demo-form-inline">
      <el-form-item label="类型">
        <el-select v-model="eventType" placeholder="请选择" clearable>
          <el-option label="通知" :value="1"> </el-option>
          <el-option label="进度" :value="2"> </el-option>
          <el-option label="汇报" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="eventStatus" placeholder="请选择" clearable>
          <el-option label="待查看" :value="0"> </el-option>
          <el-option label="已查看" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="阶段">
        <el-select v-model="eventStage" placeholder="请选择" clearable>
          <el-option
            :label="s.name"
            :value="s.vlaue"
            v-for="(s, i) in stageStep"
            :key="i"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="search" type="primary">查询</el-button>
        <el-button v-if="type == 'mec'" @click="openStage" type="success"
          >阶段设置</el-button
        >
        <el-button @click="openAddPlan" type="warning">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column prop="title" label="主题"> </el-table-column>
      <el-table-column prop="admin_name" label="执行人"></el-table-column>
      <el-table-column label="事件内容">
        <template v-slot="{ row }">
          <div
            class="ell"
            @click="
              plancontextShow = true;
              plancontext = row.context;
            "
          >
            {{ row.context }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80px">
        <template v-slot="{ row }">
          <el-tag type="success" v-if="row.status == 1">已查看</el-tag>
          <el-tag type="info" v-if="row.status == 0">未查看</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="complete_time" label="事件完成时间">
      </el-table-column>
      <el-table-column label="查看时间" prop="look_time">
        <template v-slot="{ row }">
          <div>查看：{{ row.look_time }}</div>
          <div v-if="row.need_confirm">确认：{{ row.confirm_time }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="100px">
        <template v-slot="{ row }">
          <div v-if="row.status != 2">
            <el-button
              v-if="row.admin_id == manager.id"
              type="warning"
              size="small"
              @click="openUpdatePlan(row)"
              >修改</el-button
            >
          </div>
          <el-button
            v-else
            type="success"
            size="small"
            @click="toPlanDetails(row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>

    <!-- 回访事件 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      title="添加事件"
      :destroy-on-close="true"
    >
      <el-form label-width="80px">
        <el-form-item label="类型" required>
          <el-radio v-model="planForm.type" :label="1">通知</el-radio>
          <el-radio v-model="planForm.type" :label="2">进度</el-radio>
          <el-radio v-model="planForm.type" :label="3">汇报</el-radio>
        </el-form-item>
        <el-form-item label="标题" required>
          <el-input
            type="textarea"
            :rows="2"
            v-model="planForm.title"
            placeholder="请输入事件内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" required>
          <el-input
            type="textarea"
            :rows="4"
            v-model="planForm.context"
            placeholder="请输入事件内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <images-uploader
            :urls="curPlan.images"
            @getImgUrl="(v) => (planForm.images = v)"
          ></images-uploader>
        </el-form-item>

        <el-form-item label="阶段" required>
          <el-radio-group v-model="planForm.stage">
            <el-radio v-for="(s, i) in stageStep" :key="i" :label="s.vlaue">{{
              s.name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时间" required>
          <el-date-picker
            v-model="planForm.complete_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="对方确认">
          <el-switch v-model="planForm.need_confirm"> </el-switch>
        </el-form-item>
        <el-form-item label="短信通知" v-if="mode == 'add'">
          <el-switch v-model="planForm.sms"> </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="mode == 'add'"
          style="background-color: #ff7e00; color: #ffffff"
          @click="addplan"
          >确 定</el-button
        >
        <el-button
          v-else
          style="background-color: #ff7e00; color: #ffffff"
          @click="updateplan"
          >修 改</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="事件内容" :visible.sync="plancontextShow" width="30%">
      <p>
        {{ plancontext }}
      </p>
    </el-dialog>

    <el-dialog title="阶段设置" :visible.sync="stageShow" width="30%">
      <p>
        <el-radio-group v-model="stage">
          <el-radio v-for="(s, i) in stageStep" :key="i" :label="s.vlaue">{{
            s.name
          }}</el-radio>
        </el-radio-group>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stageShow = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="confirmStage"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import adminSelect from "@/components/select/adminSelect.vue";
import { ObjectChangedValue } from "@/js/utils";
export default {
  components: {
    adminSelect,
  },
  props: {
    target: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      id: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      name: "",
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      dialogVisible: false,
      planForm: {},
      curPlan: {},
      mode: "add",
      user_name: "",
      eventType: "",
      eventStatus: "",
      eventStage: "",
      planStartTime: "",
      planEndTime: "",
      timeRange: [],
      plancontextShow: false,
      plancontext: "",
      loading: false,
      stageShow: false,
      curStage: 1,
      stage: 1,
      stageStep: [
        {
          name: "协议签订",
          vlaue: 1,
        },
        {
          name: "门店设计",
          vlaue: 2,
        },
        {
          name: "装修施工",
          vlaue: 3,
        },
        {
          name: "清洁验收",
          vlaue: 4,
        },
        {
          name: "设备入场",
          vlaue: 5,
        },
        {
          name: "开业典礼",
          vlaue: 6,
        },
        {
          name: "试营业",
          vlaue: 7,
        },
        {
          name: "正式营业",
          vlaue: 8,
        },
      ],
    };
  },
  watch: {
    target: {
      handler: function (nv) {
        this.curStage = nv.stage;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    confirmStage() {
      let url = "/user/mastermechanism/update";
      let data = {
        id: this.target.id,
        stage: this.stage,
      };
      this.$post(url, data).then((res) => {
        this.stageShow = false;
        this.curStage = this.stage;
      });
    },
    openStage() {
      this.stageShow = true;
    },
    load() {
      if (!this.loading) {
        this.loading = true;
        this.getPlanList();
      }
    },
    changeSelf() {
      this.planForm.admin_name = this.manager.name;
      this.planForm.admin_id = this.manager.id;
    },
    toPlanDetails(row) {
      this.$router.push({
        name: "consultantPlanDetails",
        params: {
          id: row.id,
        },
      });
    },
    search() {
      this.currentPage = 1;
      this.getPlanList();
    },
    todetails(row) {
      this.$router.push({
        name: "consultantDetails",
        params: {
          id: row.id,
        },
      });
    },
    changeTime(v) {
      if (v.length >= 2) {
        this.planStartTime = v[0];
        this.planEndTime = v[1];
      } else {
        this.planStartTime = new Date().Format("yyyy-MM-dd") + " 00:00:00";
        this.planEndTime = new Date().Format("yyyy-MM-dd") + " 23:59:59";
      }
    },
    getPlanList() {
      let data = {
        start_time: this.planStartTime || null,
        end_time: this.planEndTime || null,
        pageSize: 10,
        currentPage: this.currentPage,
        type: this.eventType || null,
        stage: this.eventStage || null,
        status: this.eventStatus || null,
      };
      if (this.type == "mec") {
        data.mechanism_id = this.target.id;
      } else if (this.type == "coop") {
        data.coop_id = this.target.id;
      }
      let url = "/user/eventMessage/queryManagerListPage";
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getPlanList();
    },

    openAddPlan() {
      this.planForm = { type: 2, sms: true, need_confirm: false };
      this.dialogVisible = true;
      this.mode = "add";
      this.changeSelf();

      this.planForm.stage = this.curStage;
    },
    openUpdatePlan(plan) {
      this.mode = "edit";
      this.dialogVisible = true;
      this.planForm = plan;
      this.curPlan = { ...plan };
      this.changeSelf();
    },

    changeConsultant(admin) {
      this.planForm.admin_id = admin.id;
      this.planForm.admin_name = admin.name;
    },
    addplan() {
      if (this.type == "mec") {
        this.planForm.target_name = this.target.mechanism_name;
        this.planForm.mechanism_id = this.target.id;
      } else if (this.type == "coop") {
        this.planForm.target_name = this.target.title;
        this.planForm.coop_id = this.target.id;
      }
      if (this.checkForm()) {
        this.$axios({
          url: "/user/eventMessage/insert",
          data: this.planForm,
          method: "post",
        }).then((res) => {
          this.$message("添加成功");
          this.planForm = {};
          this.dialogVisible = false;
          this.getPlanList();
        });
      }
    },
    updateplan() {
      if (this.checkForm()) {
        let newInfo = ObjectChangedValue(this.curPlan, this.planForm);
        if (Object.keys(newInfo).length == 0) {
          this.dialogVisible = false;
          return;
        }
        newInfo.id = this.curPlan.id;
        this.$axios({
          url: "/user/eventMessage/update",
          data: newInfo,
          method: "post",
        }).then((res) => {
          this.planForm = {};
          this.dialogVisible = false;
          this.getPlanList();
        });
      }
    },
    checkForm() {
      if (!this.planForm.admin_name) {
        this.$message("请选择执行人");
        return false;
      }
      if (!this.planForm.type) {
        this.$message("请选择类型");
        return false;
      }
      if (!this.planForm.title) {
        this.$message("请输入标题");
        return false;
      }
      if (!this.planForm.complete_time) {
        this.$message("请选择完成时间");
        return false;
      }
      return true;
    },
  },
};
</script>
  
<style lang="less">
.ell {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #0090ff;
  }
}
</style>
  