<template>
  <div class="">
    <div class="cash-wrap">
      <div class="cash-item">
        <div class="cash-name">账户余额</div>
        <div class="cash-value">¥{{ cashInfo.cash }}</div>
      </div>
      <div class="cash-item">
        <div class="cash-name">累计收益</div>
        <div class="cash-value">¥{{ cashInfo.history }}</div>
      </div>
      <div class="cash-item">
        <div class="cash-name">今日收益</div>
        <div class="cash-value">
          ¥{{ cashInfo.today }}
        </div>
      </div>
      <div class="cash-item">
        <div class="cash-name">开店基金</div>
        <div class="cash-value">¥{{ cashInfo.open_fund }}</div>
      </div>
    </div>
    <el-form :inline="true">
      <el-form-item label="收益类型">
        <el-select v-model="type" placeholder="选择收益类型" clearable>
          <el-option label="课程收益" :value="3"></el-option>
          <el-option label="开店基金入账" :value="4"></el-option>
          <el-option label="支出/提现" value="2,5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>
          {{ typeMap[row.type] }}
        </div>
      </template>
      <template #income="{ row }">
        <div>
          <el-tag type="success" size="small" v-if="row.is_income"
            >收入</el-tag
          >
          <el-tag type="danger" size="small" v-else
            >支出</el-tag
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "说明", value: "name" },
        { name: "金额(元)", value: "amount" },
        { name: "类目", value: "type", type: "custom" },
        { name: "收益类型", value: "income", type: "custom" },
        { name: "时间", value: "create_time" },
      ],
      Total: 0,
      type: "",
      id: "",
      typeMap: {
        1: "场馆费",
        2: "提现",
        3: "课时费",
        4: "开店基金入账",
        5: "开店基金扣除",
      },
      load: false,
      cashInfo: {
        cash: 0,
        today: 0,
        history: 0,
        open_fund: 0,
      },
    };
  },

  mounted() {
    this.id = this.$route.query.mecID;
  },

  methods: {
    init() {
      if (!this.load) {
        this.load = true;
        this.getList(1);
        this.getCash();
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    getCash() {
      this.$axios({
        url: "/user/mechanismAccount/getUnionInfo",
        params: {
          mechanism_id: this.id,
        },
      }).then((res) => {
        this.cashInfo = res.data.data;
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/mechanismAccountRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          mechanism_id: this.id,
          types: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cash-wrap {
  display: flex;
  margin-bottom: 10px;
}
.cash-item {
  padding: 10px 20px;
  margin-right: 20px;
  background-color: rgba(243, 202, 140, 0.7);
  border-radius: 10px;
  min-width: 100px;
}
.cash-name {
  font-size: 14px;
}
.cash-value {
  font-size: 20px;
  margin-top: 10px;
}
</style>